body, html {
  font-family: 'Inter', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
}

@media screen and (min-width: 768px) {
  body, html {
    font-size: 12px;
  }
}


.App {
  max-width: 480px;
  width: 100%;
  color: #282730;
  margin: 0 auto;
}

.App-header {
  text-align: center;
  padding: 1rem;
}

.main-content {
  padding: 1rem 2.25rem;
}

.main-title {
  margin-bottom: 2.5rem;
}

.item-image-wrapper {
  position: relative;
  margin: 1.5rem 0;
}

.item-image {
  display: block;
  width: auto;
  height: 100%;
  max-height: 15rem;
  margin: 0 auto;
  max-width: 100%;
}

.glow {
  background: rgba(255,232,253);
  filter: blur(30px);
  width: 170px;
  height: 170px;
  position: absolute;
  z-index: -1;
  left: -25px;
  bottom: -25px;
}

.glow.glow-alternate {
  background: #E8E9FF;
  filter: blur(30px);
  z-index: -1;
  right: -25px;
  top: -25px;
  left: unset;
  bottom: unset;
}

.item-name {
  font-size: 32px;
  font-weight: 700;
  padding: 0.75rem 0;
  color: #32454F;
}

.item-info {
  color: #8EA0AA;
  padding: 0.25rem 0;
}

.info-box {
  margin-bottom: 0.75rem;
}

.info-box > div {
  margin-bottom: 4px;
}

.tag {
  border-radius: 4px;
  padding: 0.5rem;
}

.tag.tag-checked-out {
  color: red;
  background-color: #ffe8e8;
}

.tag.tag-available {
  color: #068A4B;
  background-color: #E8FFEE;
}

button {
  width: calc(100% - 32px);
  padding: 1.5rem;
  font-size: 1.25rem;
  font-weight: bold;
  font-family: 'Inter';
  border: none;
  border-radius: 6px;
  position: fixed;
  bottom: 16px;
  left: 16px;
  background-color: #E8F7FF;
  color: #009CE8;
}

button.action-complete {
  background-color: #E8FFEE;
  color: #5F8C75;
}